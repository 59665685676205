<template>
  <div class="Overview">
    <div class="Header">
      <div class="HeaderContent">
        <h1>{{ currentAccount.name }}</h1>
        <TabNavigation
          v-if="isSuperUser"
          :navigationItems="navigationItems" />
      </div>
    </div>

    <div class="PageContentWrap">
      <router-view/>
    </div>
  </div>
</template>

<script>
import TabNavigation from '@/components/TabNavigation.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Overview',
  data() {
    return {
      navigationItems: [
        {
          pathName: 'Account',
          params: {},
          title: this.mixWB('ACCOUNT'),
        },
        {
          pathName: 'AccountUsers',
          params: {},
          title: this.mixWB('ACCOUNT_USERS'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentAccount',
    ]),
    isSuperUser() {
      return this.currentUser.isSuperUser || this.currentUser.isAdmin
    },
  },
  components: {
    TabNavigation,
  },
}
</script>

<style lang="stylus" scoped>
  .Overview
    display block

  .PageContentWrap, .HeaderContent
    max-width 1200px
    margin 0 auto

  .PageContentWrap
    padding: 20px
  .Header
    display block
    background-color $color_grey_lightestest
    border-bottom 1px solid $color_grey_lighter
    .HeaderContent
      padding 20px 20px 0px

</style>
